<template>
	<van-action-sheet class="HActionSheetA" v-model:show="propsData.show" :title="propsData.title"
		:close-icon="closeIcon" :close-on-click-overlay="closeOnClickOverlay" @cancel="cancel"
		@click-overlay="clickOverlay">
		<van-cell class="actionSheetA_cell" value="去充值" is-link @click="fGoRecharge">
			<!-- 使用 title 插槽来自定义标题 -->
			<template #title>
				<span class="actionSheetA_cell_title">店内余额：<em class="cE73243">￥{{propsData.payDetail.availableAmount}}</em></span>
			</template>
		</van-cell>
		<div class="actionSheetA_bottom_content">
			<p class="actionSheetA_bottom_content_b" v-if="propsData.payDetail.isShowPrePrizeAmount !== false">
				理论奖金<em>￥{{propsData.payDetail.prePrizeAmountMin && propsData.payDetail.prePrizeAmountMin !== propsData.payDetail.prePrizeAmount ? propsData.payDetail.prePrizeAmountMin + " - " + propsData.payDetail.prePrizeAmount : propsData.payDetail.prePrizeAmount}}</em>
			</p>
			<p class="actionSheetA_bottom_content_d">
				合计<em>￥{{propsData.payDetail.totalFee}}</em>
			</p>
		</div>
		<van-button class="actionSheetA_bottom_button" block native-type="submit"
			:disabled="!propsData.payDetail.isEnough" @click="fSubmit">
			支付
		</van-button>
	</van-action-sheet>
</template>

<script>
	import {
		reactive,watch
	} from 'vue';
	import {
		useRouter
	} from "vue-router";
	import {
		apiGetRechargeWay
	} from '@/api/other.js';
	export default {
		name: "HActionSheetA",
		props: {
			title: {
				type: String,
				default: "提示"
			},
			show: {
				type: Boolean,
				default: false
			},
			closeOnClickOverlay: {
				type: Boolean,
				default: false
			},
			oButton: {
				type: Object,
				default: () => ({
					isShow: true,
					isDisabled: false,
					text: "确定"
				})
			},
			// 充值跳转
			sRechargeRedirect: {
				type: String,
				default: "mine"
			},
			payDetail: {
				type: Object,
				default: () => ({})
			},
		},
		setup(props, context) {
			const router = useRouter(); //初始化路由
			const closeIcon = require("@/assets/icon/icon-017.png");
			const oRechargeWay = reactive({
				rechargeUrl: null,
				rechargeWay: "NORMAL"
			});
			const propsData = reactive({
				title: props.title,
				show: props.show,
				closeOnClickOverlay: props.closeOnClickOverlay,
				button: props.oButton,
				payDetail: props.payDetail,
				sRechargeRedirect: props.sRechargeRedirect
			});
			const cancel = () => {
				fBeforeClose('cancel');
			};
			const clickOverlay = () => {
				fBeforeClose('overlay');
			};
			const fBeforeClose = (action) => {
				context.emit("fBeforeClose", action, (tf) => {
					propsData.show = tf;
				})
			};
			const fSubmit = () => {
				context.emit("fSubmit", (tf) => {
					propsData.show = tf;
				})
			};
			const fGetRechargeWay = () => {
				apiGetRechargeWay().then(res => {
					let tData = res.result ? res.result : {};
					oRechargeWay.rechargeUrl = tData.rechargeUrl;
					oRechargeWay.rechargeWay = tData.rechargeWay;
				})
			};
			// 去充值
			const fGoRecharge = () => {
				if ( oRechargeWay.rechargeWay === "QRCODE" ) {
					router.push({
						path: '/web',
						query: {
							type: "recharge",
							url: oRechargeWay.rechargeUrl,
							redirect: propsData.sRechargeRedirect
						}
					});
				} else {
					router.replace({
						path: '/recharge',
						query: {
							redirect: "mine"
						}
					});
				}
			};
			// watch只能同时监听两个数值
			watch(
				[() => props.show],
				([newShow]) => {
					// console.log(newShow);
					propsData.show = newShow;
				}
			);
			// fGetRechargeWay();
			return {
				closeIcon,
				propsData,
				fGetRechargeWay,
				cancel,
				clickOverlay,
				fSubmit,
				fGoRecharge
			}
		}
	}
</script>

<style scoped lang="scss">
	.actionSheetA_cell_title {
		display: flex;
		align-items: center;
		font-size: 0.3733rem;
		font-weight: 400;
		color: #383838;
		&>em {
			font-size: 0.48rem;
			font-weight: bold;
			color: #E2335D;
		}
	}
</style>
