<template>
	<div class="tableC app_scroll">
		<div class="tableC_table" v-if="!isDisabled">
			<ul class="tableC_title">
				<li>过关</li>
				<li>单注组合</li>
				<li>倍数</li>
				<li >理论奖金</li>
			</ul>
			<ul v-for="(item,index) in aContent" :key="index" class="tableC_row " :class="{'tableC_row_open': item.isOpen, 'tableC_row_openEnd': item.isOpen && index+1 === aContent.length, 'tableC_row_noOpenEnd': !item.isOpen && index+1 === aContent.length, 'tableC_row_disabled': item.isDisable}">
				<li>
					<span @click="fChangeDisable(item)">{{item.mixTypes}}</span>
				</li>
				<li>
					<div v-for="(itemA, indexA) in (item.groupList ? item.groupList : item.singleResponses).slice(0, 2)" :key="indexA">
						<p class="mtb5">{{ aLotteryType === 'JCLQ' ? itemA.guestTeamName : itemA.homeTeamName}}</p>
						<p :class="{'cFF7671' : !item.isDisable}">{{itemA.betInfoOption ? itemA.betInfoOption : itemA.option}} ({{itemA.realOdds}})</p>
					</div>
					<img v-if="item.isOpen && !item.isDisable" src="~@/assets/icon/icon-030.png" alt="" @click="item.isOpen = !item.isOpen">
					<img v-else-if="!item.isOpen && !item.isDisable" src="~@/assets/icon/icon-029.png" alt="" @click="item.isOpen = !item.isOpen">
					<img v-else-if="item.isOpen && item.isDisable" src="~@/assets/icon/icon-032.png" alt="" @click="item.isOpen = !item.isOpen">
					<img v-else src="~@/assets/icon/icon-031.png" alt="" @click="item.isOpen = !item.isOpen">
				</li>
				<li>
					<template v-if="isDisabled">
						{{item.multiple}}
					</template>
					<van-stepper v-else class="tableC_row_stepper" v-model="item.multiple" integer :disabled="item.isDisable" :min="item.isDisable ? 0 : 1" max="100000" @change="fChange" />
				</li>
				<li >
					{{item.multiple*(parseInt((item.singleAmount*100).toFixed(2)))/100}}
				</li>
				<div class="tableC_row_drawer" v-show="item.isOpen">
					<div class="tableC_row_drawer_title">
						组合方案
					</div>
					<div class="tableC_row_drawer_column">
						<span>场次</span>
						<template v-if="aLotteryType === 'JCLQ'">
							<span>客队</span>
							<span>主队</span>
						</template>
						<template v-else>
							<span>主队</span>
							<span>客队</span>
						</template>
						<span>投注内容</span>
					</div>
					<div class="tableC_row_drawer_row" v-for="(itemA, indexA) in (item.groupList ? item.groupList : item.singleResponses)" :key="'detail_'+indexA">
						<span>{{itemA.weekStr}}{{itemA.gameNo}}</span>
						<template v-if="aLotteryType === 'JCLQ'">
							<span>{{itemA.guestTeamName}}</span>
							<span>{{itemA.homeTeamName}}</span>
						</template>
						<template v-else>
							<span>{{itemA.homeTeamName}}</span>
							<span>{{itemA.guestTeamName}}</span>
						</template>
						<span class="cFF7671">
							<div>
								<p>{{itemA.betInfoOption ? itemA.betInfoOption : itemA.option}}</p>
								<p>({{itemA.realOdds}})</p>
							</div>
						</span>
					</div>
				</div>
			</ul>
		</div>
		<div class="tableC_table" v-else>
			<ul class="tableC_title">
				<li>过关</li>
				<li>单注组合</li>
				<li>倍数</li>
			</ul>
			<ul v-for="(item,index) in aContent" :key="index" class="tableC_row " :class="{'tableC_row_open': item.isOpen, 'tableC_row_openEnd': item.isOpen && index+1 === aContent.length, 'tableC_row_noOpenEnd': !item.isOpen && index+1 === aContent.length, 'tableC_row_disabled': item.isDisable}">
				<li>
					<span @click="fChangeDisable(item)">{{item.mixTypes}}</span>
				</li>
				<li>
					<div v-for="(itemA, indexA) in (item.groupList ? item.groupList : item.singleResponses).slice(0, 2)" :key="indexA">
						<p class="mtb5">{{ aLotteryType === 'JCLQ' ? itemA.guestTeamName : itemA.homeTeamName}}</p>
						<p :class="{'cFF7671' : !item.isDisable}">{{itemA.betInfoOption ? itemA.betInfoOption : itemA.option}} ({{itemA.realOdds}})</p>
					</div>
					<img v-if="item.isOpen && !item.isDisable" src="~@/assets/icon/icon-030.png" alt="" @click="item.isOpen = !item.isOpen">
					<img v-else-if="!item.isOpen && !item.isDisable" src="~@/assets/icon/icon-029.png" alt="" @click="item.isOpen = !item.isOpen">
					<img v-else-if="item.isOpen && item.isDisable" src="~@/assets/icon/icon-032.png" alt="" @click="item.isOpen = !item.isOpen">
					<img v-else src="~@/assets/icon/icon-031.png" alt="" @click="item.isOpen = !item.isOpen">
				</li>
				<li style="border-right: 0.0266rem solid #F1F1F1;">
					<template v-if="isDisabled">
						{{item.multiple}}
					</template>
					<van-stepper v-else class="tableC_row_stepper" v-model="item.multiple" integer :disabled="item.isDisable" :min="item.isDisable ? 0 : 1" max="100000" @change="fChange" />
				</li>
				<div class="tableC_row_drawer" v-show="item.isOpen">
					<div class="tableC_row_drawer_title">
						组合方案
					</div>
					<div class="tableC_row_drawer_column">
						<span>场次</span>
						<template v-if="aLotteryType === 'JCLQ'">
							<span>客队</span>
							<span>主队</span>
						</template>
						<template v-else>
							<span>主队</span>
							<span>客队</span>
						</template>
						<span>投注内容</span>
					</div>
					<div class="tableC_row_drawer_row" v-for="(itemA, indexA) in (item.groupList ? item.groupList : item.singleResponses)" :key="'detail_'+indexA">
						<span>{{itemA.weekStr}}{{itemA.gameNo}}</span>
						<template v-if="aLotteryType === 'JCLQ'">
							<span>{{itemA.guestTeamName}}</span>
							<span>{{itemA.homeTeamName}}</span>
						</template>
						<template v-else>
							<span>{{itemA.homeTeamName}}</span>
							<span>{{itemA.guestTeamName}}</span>
						</template>
						<span class="cFF7671">
							<div>
								<p>{{itemA.betInfoOption ? itemA.betInfoOption : itemA.option}}</p>
								<p>({{itemA.realOdds}})</p>
							</div>
						</span>
					</div>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		ref,
		watch
	} from "vue";
	import {
		fnKeepFigures,
		fnFloatMultiply
	} from "@/utils/public.js";
	export default {
		name:"HTableC",
		props: {
			aContent: {
				type: Array,
				required: true
			},
			aLotteryType: {
				type: String,
				default: "JCZQ"
			},
			// 是否可以点击禁用
			isDisabled: {
				type: Boolean,
				default: false
			},
		},
		setup(props, context) {
			const p_aContent = ref([]);
			watch(
					[() => props.aContent],
					(newValue) => {
						p_aContent.value = newValue[0]
					}
			);
			// 改变时候禁用状态
			const fChangeDisable = (row) => {
				let tNumDisable = 0;
				p_aContent.value.forEach(item => {
					if (!item.isDisable) tNumDisable+=1;
				})
				if (!row.isDisable && tNumDisable <= 1) {
					return
				}
				if (props.isDisabled) return;
				if (!row.isDisable) {
					row.multiple = 0;
				} else {
					row.multiple = 15;
				}
				row.isDisable = !row.isDisable;
			};
			// 当倍数变化时触发的事件
			const fChange = () => {
				context.emit("fChangeMultiple")
			};
			return {
				fChangeDisable,
				fChange,

				fnKeepFigures,
				fnFloatMultiply
			}
		}
	}
</script>

<style scoped lang="scss">
	.tableC {
		background-color: #fff;

		.tableC_table {
			margin: 0.3466rem;
			background: #FAFAFA;
			overflow: hidden;
			&>ul {
				display: flex;
				flex-wrap: wrap;
				background: #FFFFFF;
				&>li {
					flex: 1;
					text-align: center;
					background: #FFFFFF;
					border-left: 0.0266rem solid #F1F1F1;
				}
				&>li:first-of-type {
					width: 1.3333rem;
					flex-grow: 2;
				}
				&>li:last-of-type {
					border-right: 0;
					width: 1.3333rem;
				}
				&>li:nth-child(2) {
					flex-grow: 4;
				}
				&>li:nth-child(3) {
					flex-grow: 2;
				}
				&>li:nth-child(4) {
					flex-grow: 2;
					border-right: 0.0266rem solid #F1F1F1;
				}
			}
			&>ul:last-of-type {
				border-bottom: 0;
			}
			.tableC_title {

				&>li {
					border-top: 0.0266rem solid #F1F1F1;
					padding: 0.16rem 0;
					text-align: center;
					background: #FAFAFA;
					font-size: 0.32rem;
					font-weight: 600;
					color: #383838;
					line-height: 0.4533rem;
				}
				&>li:nth-child(1) {
					border-radius: 0.1066rem 0px 0px 0px;
				}
				&>li:nth-child(4) {
					border-radius: 0px 0.1066rem 0px 0px;
				}
			}


		}
	}
</style>
